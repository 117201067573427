import React, { useState } from 'react';
import './App.css';
import logo_blueform from './logo_blueform.png';
import logo_software from './logo_software.png';
import logo_fi from './fi_logo.png';



function App() {


  const [link, setLink] = useState("https://bhp.blueform.usermd.net/BHP1.1");
  const links = [
    {
      id: 1,
      url: 'https://bhp.blueform.usermd.net/BHP1.1',
      name: 'Moduł 1 część 1'
    },
    {
      id: 2,
      url: 'https://bhp.blueform.usermd.net/BHP1.2',
      name: 'Moduł 1 część 2'
    },
    {
      id: 3,
      url: 'https://bhp.blueform.usermd.net/BHP1.3',
      name: 'Moduł 1 część 3'
    },
    {
      id: 4,
      url: 'https://bhp.blueform.usermd.net/BHP2.1',
      name: 'Moduł 2 część 1'
    },
    {
      id: 5,
      url: 'https://bhp.blueform.usermd.net/BHP2.2',
      name: 'Moduł 2 część 2'
    },
    {
      id: 6,
      url: 'https://bhp.blueform.usermd.net/BHP2.3',
      name: 'Moduł 2 część 3'
    }
    ,
    {
      id: 7,
      url: 'https://bhp.blueform.usermd.net/BHP3.1',
      name: 'Moduł 3 część 1'
    }
    ,
    {
      id: 8,
      url: 'https://bhp.blueform.usermd.net/BHP3.2',
      name: 'Moduł 3 część 2'
    }
  ]
  

  return (
    <div className="App">
      <header className="naglowek">
        <div style={{width: '30%',display: 'flex', alignItems: 'center'}}>
        <a href='https://www.blueform.pl/' target="_blank">
          <img className='logo_blueform' src={logo_blueform}  alt='logo_blueform'></img></a>
        </div>
        <div style={{width: '70%',height: '80px',display: 'flex', alignItems: 'center'}}></div>
      {/* <img className='logo_software' src={logo_software} alt='logo_software'></img>
<img className='logo_fi' src={logo_fi} alt='logo_fi'></img> */}
      </header>

        <div className='list'>
          {links.map((l) => 
            <div key={l.id}>
              <button
                style={l.url === link ? { backgroundColor: '#027294', boxShadow: '0 5px #00455a'} : {}} 
                onClick={() => {setLink(l.url)
              }}>{l.name}</button>
            </div>
            ) } 

        
        </div>
        <div className='right'>
      <iframe className='frame' src={link} title="BHP1.1"></iframe><br />
      </div>
      <div className='footer'>
            <footer className='footer-title'>
               ©️ Blueform
            </footer>
            <div className='footer-content row'>
            <div class="logo-image">
              <div class="software"> 
               <a href='https://blueformsoftware.pl/' target="_blank"> <img className="zdj2" src={logo_software} alt="logo_blueform"/> </a>
              <p>Kontakt tel: +48 32 441 28 77</p>
             <p> E-mail: biuro@blueformsoftware.pl </p>
              </div>
            </div>
            <div class="logo-image">
              <div class="firmotron">
               <a href='https://firmotron.pl/?cid=630' target="_blank"><img className="zdj3" src={logo_fi} alt="logo_blueform"/></a>
              </div>
            </div>

            
            
            </div>
         </div>

    </div>

  );
}

export default App;
